import { getAllSiteNodesParams, SiteNode } from './models';

export const getAllSiteNodes = ({ nodes, lang }: getAllSiteNodesParams): SiteNode[] =>
  nodes
    .filter((item) => {
      if (item.context?.lang && lang) {
        return [lang].includes(item.context?.lang);
      }

      return true;
    })
    .map(({ id, path, context }) => ({
      name: context?.name,
      id,
      path,
    }));
