import React, { FC, useState } from 'react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';
import { toggleState } from 'utils/toggleState';

import { AccordionProps } from './models';

import './Accordion.scss';

const Accordion: FC<AccordionProps> = ({
  title,
  content,
  customClass,
  elements,
  isAccordionOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isAccordionOpen);

  const accordionClassess = classNames('accordion', customClass, {
    'accordion--collapsed': isOpen,
  });
  const renderContent = () =>
    content ? (
      <Typography
        dangerouslySetInnerHTML={content}
        variant="paragraph1"
        weight="regular"
        customClass="accordion__description"
      />
    ) : null;

  const renderElements = () => elements || null;

  return (
    <div className={accordionClassess} data-testid="accordion-item">
      <Button
        variant={BUTTON.VARIANT.ACCORDION}
        onClick={() => toggleState(setIsOpen)}
        customClass="accordion__button"
      >
        <Typography variant="heading5" weight="regular" customClass="accordion__title">
          {title}
        </Typography>
        <Icon icon={isOpen ? faMinus : faPlus} className="accordion__icon" />
      </Button>
      <div className="accordion__item" data-testid="accordion-content">
        {renderContent()}
        {renderElements()}
      </div>
    </div>
  );
};

export default Accordion;
