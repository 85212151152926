/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, ReactNode } from 'react';
import { useBreadcrumbs } from 'hooks/useBreadcrumbs';
import { BREADCRUMBS_LINK_SEPARATOR } from 'hooks/useBreadcrumbs/constants';
import { IBreadcrumb } from 'hooks/useBreadcrumbs/models.d';

import { Container } from 'layout';
import ArrowIcon from 'common/IconsSVG/ArrowIcon';
import Link from 'common/Link';
import Typography from 'common/Typography';

import './Breadcrumbs.scss';

const Breadcrumbs: FC = () => {
  const { breadcrumbs } = useBreadcrumbs();

  const breadcrumbLinkNode = ({ name, link, isNextToLast, isLastPage }: IBreadcrumb): ReactNode =>
    !isLastPage ? (
      <React.Fragment key={name}>
        <Typography variant="label" customClass="hide--on-mobile">
          <Link customClass="breadcrumbs__link" url={link}>
            {name}
          </Link>
          <span className="breadcrumbs__separator">{BREADCRUMBS_LINK_SEPARATOR}</span>
        </Typography>

        {isNextToLast ? (
          <Typography variant="label" customClass="hide--on-desktop">
            <Link customClass="breadcrumbs__link" url={link}>
              <ArrowIcon />
            </Link>
          </Typography>
        ) : null}
      </React.Fragment>
    ) : (
      <Typography variant="label" weight="regular" key={link} customClass="breadcrumbs__current">
        {name}
      </Typography>
    );

  return (
    <Container fluid data-testid="breadcrumbs" className="breadcrumbs">
      <div className="breadcrumbs__wrapper">
        {breadcrumbs.map((breadcrumb) => breadcrumbLinkNode(breadcrumb))}
      </div>
    </Container>
  );
};

export default Breadcrumbs;
