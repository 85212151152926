import { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { unnecessarySeparator } from 'shared/regexp';

import { BREADCRUMBS_LINK_SEPARATOR, HOME_PATH } from './constants';
import { IBreadcrumb, IBreadcrumbsHelperReturn, useBreadcrumbsParams } from './models';
import { getAllSiteNodes } from './utils';

export const useBreadcrumbs = ({
  lang = null,
}: useBreadcrumbsParams = {}): IBreadcrumbsHelperReturn => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const { pathname } = useLocation();
  const {
    allSitePage: { nodes },
  } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          id
          path
          context {
            name
          }
        }
      }
    }
  `);

  const allSiteNodes = getAllSiteNodes({ nodes, lang });
  const pathElements = [HOME_PATH, ...pathname.split(BREADCRUMBS_LINK_SEPARATOR).filter(Boolean)];

  const createBreadCrumbs = () => {
    let slug: string = '';

    pathElements.forEach((page, index) => {
      slug += `${BREADCRUMBS_LINK_SEPARATOR}${page}${BREADCRUMBS_LINK_SEPARATOR}`;

      const [breadCrumb] = allSiteNodes
        .filter(
          (siteNode) =>
            siteNode.path === slug.replace(unnecessarySeparator, BREADCRUMBS_LINK_SEPARATOR)
        )
        .map(({ name, path: link }) => ({
          name,
          link,
          isNextToLast: index === pathElements.length - 2,
          isLastPage: index === pathElements.length - 1,
        }));

      if (!breadCrumb) return;

      setBreadcrumbs((state) => [...state, breadCrumb]);
    });
  };

  useEffect(() => {
    createBreadCrumbs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { breadcrumbs };
};
