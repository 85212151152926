import React, { FC } from 'react';

import { Container } from 'layout';
import Accordion from 'components/Accordion';

import { AccordionListProps } from './models';

const AccordionList: FC<AccordionListProps> = ({ items, customClass }) => {
  const renderAccordionList = items?.map(({ question, answer }) => (
    <Accordion key={question} title={question} content={answer} />
  ));

  return (
    <Container fluid noPadding className={customClass}>
      <Container element="section">{renderAccordionList}</Container>
    </Container>
  );
};

export default AccordionList;
