import React, { FC } from 'react';
import { graphql } from 'gatsby';
import AccordionList from 'containers/AccordionList';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Breadcrumbs from 'components/Breadcrumbs';

import { PaiaManualPageProps } from './models';

import './PaiaManualPage.scss';

const PaiaManualPage: FC<{ data: PaiaManualPageProps }> = ({
  data: {
    paiaManual: { urls, introduction, pageContent, pageName, seo },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Breadcrumbs />
      <div className="paia-manual">
        <Container>
          <Typography variant="heading2" weight="medium" customClass="paia-manual__title">
            {title}
          </Typography>
          <Typography
            customClass="paia-manual__introduction"
            variant="paragraph1"
            dangerouslySetInnerHTML={introduction}
          />
        </Container>
        <div className="paia-manual__accordion-list">
          <AccordionList items={pageContent} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PaiaManualPageQuery($lang: String) {
    paiaManual(lang: { eq: $lang }) {
      pageName
      urls {
        lang
        href
      }
      seo {
        ...SeoFragment
      }
      title
      introduction
      pageContent {
        ...AccordionItemFragment
      }
    }
  }
`;

export default PaiaManualPage;
