import React from 'react';

const ArrowIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6569 24.7782L20.9393 18.0607L23.0607 15.9393L31.8995 24.7782L23.0607 33.617L20.9393 31.4957L27.6569 24.7782Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowIcon;
